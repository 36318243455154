[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing="linear"], body[data-aos-easing="linear"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .25, .75, .75);
}

[data-aos][data-aos][data-aos-easing="ease"], body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing="ease-in"], body[data-aos-easing="ease-in"] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing="ease-out"], body[data-aos-easing="ease-out"] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-out"], body[data-aos-easing="ease-in-out"] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-back"], body[data-aos-easing="ease-in-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

[data-aos][data-aos][data-aos-easing="ease-out-back"], body[data-aos-easing="ease-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-back"], body[data-aos-easing="ease-in-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
}

[data-aos][data-aos][data-aos-easing="ease-in-sine"], body[data-aos-easing="ease-in-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.47, 0, .745, .715);
}

[data-aos][data-aos][data-aos-easing="ease-out-sine"], body[data-aos-easing="ease-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.39, .575, .565, 1);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-sine"], body[data-aos-easing="ease-in-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
}

[data-aos][data-aos][data-aos-easing="ease-in-quad"], body[data-aos-easing="ease-in-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quad"], body[data-aos-easing="ease-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quad"], body[data-aos-easing="ease-in-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-cubic"], body[data-aos-easing="ease-in-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-cubic"], body[data-aos-easing="ease-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-cubic"], body[data-aos-easing="ease-in-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-quart"], body[data-aos-easing="ease-in-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quart"], body[data-aos-easing="ease-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quart"], body[data-aos-easing="ease-in-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos="fade-up"] {
  transform: translate3d(0, 100px, 0);
}

[data-aos="fade-down"] {
  transform: translate3d(0, -100px, 0);
}

[data-aos="fade-right"] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos="fade-left"] {
  transform: translate3d(100px, 0, 0);
}

[data-aos="fade-up-right"] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos="fade-up-left"] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos="fade-down-right"] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos="fade-down-left"] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^="zoom"][data-aos^="zoom"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
  opacity: 1;
  transform: translateZ(0)scale(1);
}

[data-aos="zoom-in"] {
  transform: scale(.6);
}

[data-aos="zoom-in-up"] {
  transform: translate3d(0, 100px, 0)scale(.6);
}

[data-aos="zoom-in-down"] {
  transform: translate3d(0, -100px, 0)scale(.6);
}

[data-aos="zoom-in-right"] {
  transform: translate3d(-100px, 0, 0)scale(.6);
}

[data-aos="zoom-in-left"] {
  transform: translate3d(100px, 0, 0)scale(.6);
}

[data-aos="zoom-out"] {
  transform: scale(1.2);
}

[data-aos="zoom-out-up"] {
  transform: translate3d(0, 100px, 0)scale(1.2);
}

[data-aos="zoom-out-down"] {
  transform: translate3d(0, -100px, 0)scale(1.2);
}

[data-aos="zoom-out-right"] {
  transform: translate3d(-100px, 0, 0)scale(1.2);
}

[data-aos="zoom-out-left"] {
  transform: translate3d(100px, 0, 0)scale(1.2);
}

[data-aos^="slide"][data-aos^="slide"] {
  transition-property: transform;
}

[data-aos^="slide"][data-aos^="slide"].aos-animate {
  transform: translateZ(0);
}

[data-aos="slide-up"] {
  transform: translate3d(0, 100%, 0);
}

[data-aos="slide-down"] {
  transform: translate3d(0, -100%, 0);
}

[data-aos="slide-right"] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos="slide-left"] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^="flip"][data-aos^="flip"] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos="flip-left"] {
  transform: perspective(2500px)rotateY(-100deg);
}

[data-aos="flip-left"].aos-animate {
  transform: perspective(2500px)rotateY(0);
}

[data-aos="flip-right"] {
  transform: perspective(2500px)rotateY(100deg);
}

[data-aos="flip-right"].aos-animate {
  transform: perspective(2500px)rotateY(0);
}

[data-aos="flip-up"] {
  transform: perspective(2500px)rotateX(-100deg);
}

[data-aos="flip-up"].aos-animate {
  transform: perspective(2500px)rotateX(0);
}

[data-aos="flip-down"] {
  transform: perspective(2500px)rotateX(100deg);
}

[data-aos="flip-down"].aos-animate {
  transform: perspective(2500px)rotateX(0);
}

:root {
  --font-1: "Nunito Sans", sans-serif;
  --font-2: "Montserrat", sans-serif;
  --font-mono: Consolas, "Andale Mono", Courier, "Courier New", monospace;
  --color-1: #dc9852;
  --color-2: #eb6056;
  --color-3: #f2d1a6;
  --color-1-darker-1: #c6894a;
  --color-1-darker-4: #845b31;
  --color-1-lighter: #f9674e;
  --color-1-light: #f73d1d;
  --color-1-dark: #a71e06;
  --color-1-darker: #761504;
  --color-2-lighter: #f6b6b1;
  --color-2-light: #f18b84;
  --color-2-dark: #e63528;
  --color-2-darker: #c42317;
  --color-error: #ffd1d2;
  --color-success: #c8e675;
  --color-info: #d5ebfb;
  --color-notice: #fff099;
  --color-error-content: #bf4042;
  --color-success-content: #515c33;
  --color-info-content: #365368;
  --color-notice-content: #635d36;
  --color-black: #000;
  --color-gray-19: #0b0b0c;
  --color-gray-18: #161719;
  --color-gray-17: #202225;
  --color-gray-16: #2b2e32;
  --color-gray-15: #363a3f;
  --color-gray-14: #41454b;
  --color-gray-13: #4c5158;
  --color-gray-12: #565c64;
  --color-gray-11: #616871;
  --color-gray-10: #6c737d;
  --color-gray-9: #7b818a;
  --color-gray-8: #898f97;
  --color-gray-7: #989da4;
  --color-gray-6: #a7abb1;
  --color-gray-5: #b6b9be;
  --color-gray-4: #c4c7cb;
  --color-gray-3: #d3d5d8;
  --color-gray-2: #e2e3e5;
  --color-gray-1: #f0f1f2;
  --color-white: #fff;
  --color-text: var(--color-gray-10);
  --color-text-dark: var(--color-white);
  --color-text-light: var(--color-gray-12);
  --color-placeholder: var(--color-gray-11);
  --color-btn: var(--color-gray-15);
  --color-btn-text: var(--color-white);
  --color-btn-hover: var(--color-white);
  --color-btn-hover-text: var(--color-black);
  --color-btn-primary: var(--color-1);
  --color-btn-primary-text: var(--color-white);
  --color-btn-primary-hover: var(--color-white);
  --color-btn-primary-hover-text: var(--color-black);
  --color-btn-stroke: var(--color-white);
  --color-btn-stroke-text: var(--color-white);
  --color-btn-stroke-hover: var(--color-white);
  --color-btn-stroke-hover-text: var(--color-black);
  --color-body: var(--color-1);
  --color-bg: #14171b;
  --color-border: var(--color-gray-17);
  --base-size: 62.5%;
  --base-font-size: 1.8rem;
  --space: 3.2rem;
  --vspace-0_25: calc(.25 * var(--space));
  --vspace-0_5: calc(.5 * var(--space));
  --vspace-0_75: calc(.75 * var(--space));
  --vspace-1: calc(var(--space));
  --vspace-1_25: calc(1.25 * var(--space));
  --vspace-1_5: calc(1.5 * var(--space));
  --vspace-1_75: calc(1.75 * var(--space));
  --vspace-2: calc(2 * var(--space));
  --vspace-2_5: calc(2.5 * var(--space));
  --vspace-3: calc(3 * var(--space));
  --vspace-3_5: calc(3.5 * var(--space));
  --vspace-4: calc(4 * var(--space));
  --text-scale-ratio: 1.2;
  --text-size: var(--base-font-size);
  --text-xs: calc((var(--text-size) / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --text-display-1: calc(var(--text-xxxl) * var(--text-scale-ratio));
  --text-display-2: calc(var(--text-display-1) * var(--text-scale-ratio));
  --text-display-3: calc(var(--text-display-2) * var(--text-scale-ratio));
  --vspace-btn: var(--vspace-2);
}

@media screen and (width <= 480px) {
  :root {
    --base-font-size: 1.6rem;
    --space: 2.8rem;
  }
}

:root {
  --width-full: 100%;
  --width-max: 1200px;
  --width-wide: 1400px;
  --width-wider: 1600px;
  --width-widest: 1800px;
  --width-narrow: 1000px;
  --width-narrower: 900px;
  --width-grid-max: var(--width-max);
  --gutter-lg: 2rem;
  --gutter-md: 1.6rem;
  --gutter-mob: 1rem;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  text-decoration: underline;
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

*, :before, :after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  word-wrap: break-word;
  -moz-font-smoothing: grayscale;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: none;
  font-weight: normal;
  line-height: 1;
}

svg, img, video embed, iframe, object {
  max-width: 100%;
  height: auto;
}

div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

p {
  font-size: inherit;
  text-rendering: optimizelegibility;
}

em, i {
  font-style: italic;
  line-height: inherit;
}

strong, b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 60%;
  line-height: inherit;
}

ol, ul {
  list-style: none;
}

a {
  line-height: inherit;
  text-decoration: none;
}

a img {
  border: none;
}

fieldset {
  margin: 0;
  padding: 0;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
  appearance: none;
}

.row {
  max-width: var(--width-grid-max);
  flex-flow: wrap;
  width: 92%;
  margin: 0 auto;
  display: flex;
}

.row .row {
  margin-left: calc(var(--gutter-lg) * -1);
  margin-right: calc(var(--gutter-lg) * -1);
  width: auto;
  max-width: none;
}

.column {
  padding: var(--gutter-lg) var(--gutter-lg);
  flex: 1;
}

.collapse > .column, .column.collapse {
  padding: 0;
}

.row.row-wrap {
  flex-wrap: wrap;
}

.row.row-nowrap {
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
}

.row.row-y-top {
  align-items: flex-start;
}

.row.row-y-bottom {
  align-items: flex-end;
}

.row.row-y-center {
  align-items: center;
}

.row.row-stretch {
  align-items: stretch;
}

.row.row-baseline {
  align-items: baseline;
}

.row.row-x-left {
  justify-content: flex-start;
}

.row.row-x-right {
  justify-content: flex-end;
}

.row.row-x-center {
  justify-content: center;
}

.align-center {
  -ms-grid-row-align: center;
  align-self: center;
  margin: auto;
}

.align-left {
  -ms-grid-row-align: center;
  align-self: center;
  margin-right: auto;
}

.align-right {
  -ms-grid-row-align: center;
  align-self: center;
  margin-left: auto;
}

.align-x-center {
  margin-left: auto;
  margin-right: auto;
}

.align-x-left {
  margin-right: auto;
}

.align-x-right {
  margin-left: auto;
}

.align-y-center {
  -ms-grid-row-align: center;
  align-self: center;
}

.align-y-top {
  align-self: flex-start;
}

.align-y-bottom {
  align-self: flex-end;
}

.large-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.large-2 {
  flex: 0 0 16.6667%;
  max-width: 16.6667%;
}

.large-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.large-4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.large-5 {
  flex: 0 0 41.6667%;
  max-width: 41.6667%;
}

.large-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.large-7 {
  flex: 0 0 58.3333%;
  max-width: 58.3333%;
}

.large-8 {
  flex: 0 0 66.6667%;
  max-width: 66.6667%;
}

.large-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.large-10 {
  flex: 0 0 83.3333%;
  max-width: 83.3333%;
}

.large-11 {
  flex: 0 0 91.6667%;
  max-width: 91.6667%;
}

.large-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media screen and (width <= 1200px) {
  .row .row {
    margin-left: calc(var(--gutter-md) * -1);
    margin-right: calc(var(--gutter-md) * -1);
  }

  .column {
    padding: 0 var(--gutter-md);
  }

  .medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .medium-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .medium-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .medium-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .medium-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .medium-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .medium-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .medium-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (width <= 800px) {
  .row {
    width: 90%;
  }

  .tab-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .tab-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .tab-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .tab-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .tab-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .tab-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .tab-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .tab-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .tab-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .tab-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .tab-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .tab-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .hide-on-tablet {
    display: none;
  }
}

@media screen and (width <= 600px) {
  .row {
    width: 100%;
    padding-left: 6vw;
    padding-right: 6vw;
  }

  .row .row {
    margin-left: calc(var(--gutter-mob) * -1);
    margin-right: calc(var(--gutter-mob) * -1);
    padding-left: 0;
    padding-right: 0;
  }

  .column {
    padding: 0 var(--gutter-mob);
  }

  .mob-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .mob-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .mob-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mob-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .mob-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .mob-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mob-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .mob-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .mob-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mob-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .mob-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .mob-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .hide-on-mobile {
    display: none;
  }
}

@media screen and (width <= 400px) {
  .row .row {
    margin-left: 0;
    margin-right: 0;
  }

  .column {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
}

.block-large-1-8 > .column {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.block-large-1-6 > .column {
  flex: 0 0 16.6667%;
  max-width: 16.6667%;
}

.block-large-1-5 > .column {
  flex: 0 0 20%;
  max-width: 20%;
}

.block-large-1-4 > .column {
  flex: 0 0 25%;
  max-width: 25%;
}

.block-large-1-3 > .column {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.block-large-1-2 > .column {
  flex: 0 0 50%;
  max-width: 50%;
}

.block-large-full > .column {
  flex: 0 0 100%;
  max-width: 100%;
}

@media screen and (width <= 1200px) {
  .block-medium-1-8 > .column {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .block-medium-1-6 > .column {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .block-medium-1-5 > .column {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .block-medium-1-4 > .column {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .block-medium-1-3 > .column {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .block-medium-1-2 > .column {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .block-medium-full > .column {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (width <= 800px) {
  .block-tab-1-8 > .column {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .block-tab-1-6 > .column {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .block-tab-1-5 > .column {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .block-tab-1-4 > .column {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .block-tab-1-3 > .column {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .block-tab-1-2 > .column {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .block-tab-full > .column {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (width <= 600px) {
  .block-mob-1-8 > .column {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .block-mob-1-6 > .column {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .block-mob-1-5 > .column {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .block-mob-1-4 > .column {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .block-mob-1-3 > .column {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .block-mob-1-2 > .column {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .block-mob-full > .column {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (width <= 400px) {
  .stack > .column {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
}

.h-group:after {
  content: "";
  clear: both;
  display: table;
}

.is-hidden {
  display: none;
}

.is-invisible {
  visibility: hidden;
}

.h-antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.h-overflow-hidden {
  overflow: hidden;
}

.h-remove-top {
  margin-top: 0;
}

.h-remove-bottom {
  margin-bottom: 0;
}

.h-add-half-bottom {
  margin-bottom: var(--vspace-0_5) !important;
}

.h-add-bottom {
  margin-bottom: var(--vspace-1) !important;
}

.h-no-border {
  border: none;
}

.h-full-width {
  width: 100%;
}

.h-text-center {
  text-align: center;
}

.h-text-left {
  text-align: left;
}

.h-text-right {
  text-align: right;
}

.h-pull-left {
  float: left;
}

.h-pull-right {
  float: right;
}

@media screen and (width <= 1000px) {
  .block-1000-stack > .column {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (width <= 900px) {
  .w-900-stack, .block-900-stack > .column {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

html {
  font-size: var(--base-size);
}

html, body {
  height: 100%;
}

body {
  background: var(--color-body);
  font-family: var(--font-1);
  font-size: var(--text-size);
  font-style: normal;
  font-weight: normal;
  line-height: var(--vspace-1);
  color: var(--color-text);
  margin: 0;
  padding: 0;
}

a {
  color: var(--color-1-light);
  transition: all .3s ease-in-out;
}

a:hover, a:focus, a:active {
  color: #fff;
}

a:hover, a:active {
  outline: 0;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: var(--font-2);
  color: var(--color-text-dark);
  -webkit-font-variant-ligatures: common-ligatures;
  font-variant-ligatures: common-ligatures;
  text-rendering: optimizelegibility;
  font-style: normal;
  font-weight: 500;
}

h1, .h1 {
  margin-top: var(--vspace-2_5);
  margin-bottom: var(--vspace-0_75);
}

h2, .h2, h3, .h3, h4, .h4 {
  margin-top: var(--vspace-2);
  margin-bottom: var(--vspace-0_5);
}

h5, .h5, h6, .h6 {
  margin-top: var(--vspace-1_5);
  margin-bottom: var(--vspace-0_5);
}

h1, .h1 {
  font-size: var(--text-display-1);
  line-height: var(--vspace-2);
  letter-spacing: -.015em;
}

@media screen and (width <= 400px) {
  h1, .h1 {
    font-size: var(--text-xxxl);
    line-height: var(--vspace-1_75);
  }
}

h2, .h2 {
  font-size: var(--text-xxl);
  line-height: var(--vspace-1_5);
}

h3, .h3 {
  font-size: var(--text-xl);
  line-height: var(--vspace-1_25);
}

h4, .h4 {
  font-size: var(--text-lg);
  line-height: var(--vspace-1);
}

h5, .h5 {
  font-size: var(--text-md);
  line-height: calc(.875 * var(--space));
}

h6, .h6 {
  font-size: var(--text-sm);
  font-weight: 600;
  line-height: var(--vspace-0_75);
  text-transform: uppercase;
  letter-spacing: .22em;
}

.lead, .attention-getter {
  font-family: var(--font-1);
  font-weight: 400;
  font-size: var(--text-md);
  line-height: calc(1.125 * var(--space));
}

@media screen and (width <= 400px) {
  .lead, .attention-getter {
    font-size: 1.9rem;
  }
}

figure img, p img {
  vertical-align: bottom;
  margin: 0;
}

em, i, strong, b {
  font-size: inherit;
  line-height: inherit;
}

em, i {
  font-family: var(--font-1);
  font-style: italic;
}

strong, b {
  font-family: var(--font-1);
  font-weight: 600;
}

small {
  font-size: 1.3rem;
  font-weight: 500;
  line-height: calc(.5 * var(--space));
}

blockquote {
  margin: 0 0 var(--vspace-1) 0;
  padding: var(--vspace-1) var(--vspace-1_5);
  border-left: 4px solid var(--color-text-light);
  position: relative;
}

@media screen and (width <= 400px) {
  blockquote {
    padding: var(--vspace-0_75) var(--vspace-0_75);
  }
}

blockquote p {
  font-family: var(--font-1);
  font-weight: 400;
  font-size: var(--text-md);
  font-style: normal;
  line-height: calc(1.125 * var(--space));
  color: var(--color-text-dark);
  padding: 0;
}

blockquote cite {
  font-family: var(--font-1);
  font-weight: 400;
  font-size: var(--text-sm);
  line-height: var(--vspace-0_75);
  font-style: normal;
  display: block;
}

blockquote cite:before {
  content: "— ";
}

blockquote cite, blockquote cite a, blockquote cite a:visited {
  color: var(--color-text);
  border: none;
}

figure {
  margin-left: 0;
  margin-right: 0;
  display: block;
}

figure img + figcaption {
  margin-top: var(--vspace-1);
}

figcaption {
  font-size: var(--text-sm);
  text-align: center;
  margin-bottom: 0;
}

var, kbd, samp, code, pre {
  font-family: var(--font-mono);
}

pre {
  padding: var(--vspace-0_75) var(--vspace-1) var(--vspace-1);
  background: var(--color-gray-18);
  overflow-x: auto;
}

code {
  font-size: var(--text-sm);
  padding: calc(((var(--vspace-1)  - 1.6rem) / 2)  - .1rem) calc(.8rem - .1rem);
  white-space: nowrap;
  background: var(--color-gray-18);
  border: 1px solid var(--color-gray-17);
  color: var(--color-text-dark);
  border-radius: 3px;
  margin: 0 .2rem;
  line-height: 1.6rem;
}

pre > code {
  white-space: pre;
  line-height: var(--vspace-1);
  border: none;
  margin: 0;
  padding: 0;
  display: block;
}

del {
  text-decoration: line-through;
}

abbr {
  font-family: var(--font-1);
  font-variant: small-caps;
  text-transform: lowercase;
  letter-spacing: .1em;
  font-weight: 600;
}

abbr[title], dfn[title] {
  cursor: help;
  border-bottom: 1px dotted;
  text-decoration: none;
}

mark {
  background: var(--color-3);
  color: #000;
}

hr {
  border: solid var(--color-border);
  clear: both;
  margin: var(--vspace-2) 0 calc(var(--vspace-2)  - .1rem);
  border-width: .1rem 0 0;
  height: 0;
}

hr.fancy {
  margin: var(--vspace-2) 0;
  height: var(--vspace-1);
  text-align: center;
  border: none;
}

hr.fancy:before {
  content: "*****";
  letter-spacing: .3em;
}

ol {
  list-style: decimal;
}

ul {
  list-style: disc;
}

li {
  display: list-item;
}

ol, ul {
  margin-left: 1.6rem;
}

ul li {
  padding-left: .4rem;
}

ul ul, ul ol, ol ol, ol ul {
  margin: var(--vspace-0_5) 0 var(--vspace-0_5) var(--vspace-0_5);
}

ul.disc li {
  padding: 0 0 0 .8rem;
  list-style: none;
  display: list-item;
  position: relative;
}

ul.disc li:before {
  content: "";
  width: var(--vspace-0_25);
  height: var(--vspace-0_25);
  background: var(--color-1-dark);
  vertical-align: middle;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: .65em;
  left: -.9em;
}

dt {
  color: var(--color-1);
  margin: 0;
}

dd {
  margin: 0 0 0 2rem;
}

.video-container {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.video-container iframe, .video-container object, .video-container embed, .video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

img.h-pull-right {
  margin: var(--vspace-0_5) 0 var(--vspace-0_5) 2.8rem;
}

img.h-pull-left {
  margin: var(--vspace-0_5) 2.8rem var(--vspace-0_5) 0;
}

table {
  font-family: var(--font-1);
  border-collapse: collapse;
  border-width: 0;
  width: 100%;
  max-width: 100%;
}

th, td {
  padding: var(--vspace-0_5) 3.2rem calc(var(--vspace-0_5)  - .1rem);
  text-align: left;
  border-bottom: 1px solid var(--color-border);
}

th {
  padding: var(--vspace-0_5) 3.2rem;
  color: var(--color-text-dark);
  font-family: var(--font-2);
  font-weight: 600;
}

th:first-child, td:first-child {
  padding-left: 0;
}

th:last-child, td:last-child {
  padding-right: 0;
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

img, p, pre, table, blockquote, figure, figcaption, ul, ol, dl, form, fieldset, input, textarea, select, button, .btn, .video-container, .ss-custom-select {
  margin-bottom: var(--vspace-1);
}

#preloader {
  background: var(--color-bg);
  z-index: 500;
  width: 100%;
  height: 100vh;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.no-js #preloader, .oldie #preloader {
  display: none;
}

#loader {
  width: 6px;
  height: 6px;
  padding: 0;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

#loader > div {
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 0;
  left: 0;
}

#loader > div:first-of-type {
  left: 15px;
}

#loader > div:nth-of-type(3) {
  left: -15px;
}

.dots-jump > div {
  animation: 1.2s .2s infinite dots-jump;
}

.dots-jump > div:first-of-type {
  animation-delay: .4s;
}

.dots-jump > div:nth-of-type(3) {
  animation-delay: 0s;
}

@keyframes dots-jump {
  0% {
    top: 0;
  }

  40% {
    top: -6px;
  }

  80% {
    top: 0;
  }
}

.dots-fade > div {
  animation: 1.6s .4s infinite dots-fade;
}

.dots-fade > div:first-of-type {
  animation-delay: .8s;
}

.dots-fade > div:nth-of-type(3) {
  animation-delay: 0s;
}

@keyframes dots-fade {
  0% {
    opacity: 1;
  }

  40% {
    opacity: .2;
  }

  80% {
    opacity: 1;
  }
}

.dots-pulse > div {
  animation: 1.2s .2s infinite dots-pulse;
}

.dots-pulse > div:first-of-type {
  animation-delay: .4s;
}

.dots-pulse > div:nth-of-type(3) {
  animation-delay: 0s;
}

@keyframes dots-pulse {
  0% {
    transform: scale(1);
  }

  40% {
    -webkit-transform: scale(1.1);
    transform: scale(1.3);
  }

  80% {
    transform: scale(1);
  }
}

fieldset {
  margin-bottom: var(--vspace-0_5);
  border: none;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea, select {
  --input-height: var(--vspace-2);
  --input-line-height: var(--vspace-1);
  --input-vpadding: calc(((var(--input-height)  - var(--input-line-height)) / 2)  - .1rem);
  height: var(--input-height);
  padding: var(--input-vpadding) calc(2.4rem - .1rem);
  color: var(--color-placeholder);
  font-family: var(--font-1);
  font-size: var(--text-sm);
  line-height: var(--input-line-height);
  background-color: var(--color-gray-17);
  border: .1rem solid #0000;
  border-radius: 4px;
  outline: none;
  max-width: 100%;
  transition: all .3s ease-in-out;
  display: block;
}

.ss-custom-select {
  padding: 0;
  position: relative;
}

.ss-custom-select select {
  appearance: none;
  text-indent: .01px;
  text-overflow: "";
  vertical-align: middle;
  margin: 0;
}

.ss-custom-select select option {
  padding-left: 2rem;
  padding-right: 2rem;
}

.ss-custom-select select::-ms-expand {
  display: none;
}

.ss-custom-select:after {
  border-bottom: 2px solid var(--color-white);
  border-right: 2px solid var(--color-white);
  content: "";
  pointer-events: none;
  transform-origin: 66% 66%;
  width: 8px;
  height: 8px;
  margin-top: -7px;
  transition: all .15s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  right: 2.4rem;
  transform: rotate(45deg);
}

textarea {
  min-height: calc(8 * var(--space));
}

input[type="email"]:focus, input[type="number"]:focus, input[type="search"]:focus, input[type="text"]:focus, input[type="tel"]:focus, input[type="url"]:focus, input[type="password"]:focus, textarea:focus, select:focus {
  color: #fff;
  -webkit-box-shadow: 0 0 8px var(--color-gray-15);
  box-shadow: 0 0 8px var(--color-gray-15);
  border: 1px solid var(--color-gray-15);
}

label, legend {
  font-family: var(--font-1);
  font-weight: 600;
  font-size: var(--text-sm);
  line-height: var(--vspace-0_5);
  margin-bottom: var(--vspace-0_5);
  color: var(--color-text-dark);
  display: block;
}

input[type="checkbox"], input[type="radio"] {
  display: inline;
}

label > .label-text {
  font-family: var(--font-1);
  line-height: inherit;
  margin-left: 1rem;
  display: inline-block;
}

label > input[type="checkbox"], label > input[type="radio"] {
  margin: 0;
  position: relative;
  top: .2rem;
}

::-webkit-input-placeholder {
  color: var(--color-placeholder);
}

:-ms-input-placeholder {
  color: var(--color-placeholder);
}

::placeholder {
  color: var(--color-placeholder);
}

.placeholder {
  color: var(--color-placeholder) !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-1);
  transition: background-color 5000s ease-in-out;
}

input:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--color-1);
  transition: background-color 5000s ease-in-out;
}

input:-webkit-autofill:focus input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-1);
  transition: background-color 5000s ease-in-out;
}

textarea:-webkit-autofill {
  -webkit-text-fill-color: var(--color-1);
  transition: background-color 5000s ease-in-out;
}

textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-1);
  transition: background-color 5000s ease-in-out;
}

select:-webkit-autofill {
  -webkit-text-fill-color: var(--color-1);
  transition: background-color 5000s ease-in-out;
}

select:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--color-1);
  transition: background-color 5000s ease-in-out;
}

select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-1);
  transition: background-color 5000s ease-in-out;
}

.btn, button, input[type="submit"], input[type="reset"], input[type="button"] {
  --btn-height: var(--vspace-btn);
  font-family: var(--font-2);
  font-weight: 600;
  font-size: var(--text-xs);
  text-transform: uppercase;
  letter-spacing: .5em;
  height: var(--btn-height);
  line-height: calc(var(--btn-height)  - .4rem);
  margin: 0 .4rem var(--vspace-0_5) 0;
  color: var(--color-btn-text);
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-btn);
  border: .2rem solid var(--color-btn);
  padding: 0 3.2rem;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.btn:hover, button:hover, input[type="submit"]:hover, input[type="reset"]:hover, input[type="button"]:hover, .btn:focus, button:focus, input[type="submit"]:focus, input[type="reset"]:focus, input[type="button"]:focus {
  background-color: var(--color-btn-hover);
  border-color: var(--color-btn-hover);
  color: var(--color-btn-hover-text);
  outline: 0;
}

.btn.btn--primary, button.btn--primary, input[type="submit"].btn--primary, input[type="reset"].btn--primary, input[type="button"].btn--primary {
  background: var(--color-btn-primary);
  border-color: var(--color-btn-primary);
  color: var(--color-btn-primary-text);
}

.btn.btn--primary:hover, button.btn--primary:hover, input[type="submit"].btn--primary:hover, input[type="reset"].btn--primary:hover, input[type="button"].btn--primary:hover, .btn.btn--primary:focus, button.btn--primary:focus, input[type="submit"].btn--primary:focus, input[type="reset"].btn--primary:focus, input[type="button"].btn--primary:focus {
  background: var(--color-btn-primary-hover);
  border-color: var(--color-btn-primary-hover);
  color: var(--color-btn-primary-hover-text);
}

.btn.h-full-width, button.h-full-width {
  width: 100%;
  margin-right: 0;
}

.btn--small, button.btn--small {
  --btn-height: calc(var(--vspace-btn)  - var(--vspace-0_5));
  font-size: 1.2rem;
}

.btn--medium, button.btn--medium {
  --btn-height: calc(var(--vspace-btn)  + var(--vspace-0_25));
}

.btn--large, button.btn--large {
  --btn-height: calc(var(--vspace-btn)  + var(--vspace-0_5));
}

.btn--stroke, button.btn--stroke {
  border: .2rem solid var(--color-btn-stroke);
  color: var(--color-btn-stroke-text);
  background: none !important;
}

.btn--stroke:hover, button.btn--stroke:hover {
  border: .2rem solid var(--color-btn-stroke-hover);
  color: var(--color-btn-stroke-hover-text);
  background: var(--color-btn-stroke-hover) !important;
}

.btn--pill, button.btn--pill {
  border-radius: 1000px !important;
  padding-left: 3.2rem !important;
  padding-right: 3.2rem !important;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.drop-cap:first-letter {
  float: left;
  font-family: var(--font-2);
  font-weight: 600;
  font-size: calc(3 * var(--space));
  text-transform: uppercase;
  color: var(--color-text-dark);
  background: none;
  padding: 0 .125em 0 0;
  line-height: 1;
}

.lining dt, .lining dd {
  margin: 0;
  display: inline;
}

.lining dt + dt:before, .lining dd + dt:before {
  content: "\a ";
  white-space: pre;
}

.lining dd + dd:before {
  content: ", ";
  content: ", ";
}

.lining dd:before {
  content: ": ";
  margin-left: -.2em;
}

.dictionary-style dt {
  counter-reset: definitions;
  display: inline;
}

.dictionary-style dt + dt:before {
  content: ", ";
  margin-left: -.2em;
}

.dictionary-style dd {
  counter-increment: definitions;
  display: block;
}

.dictionary-style dd:before {
  content: counter(definitions, decimal) ". ";
}

.pull-quote {
  text-align: center;
  margin-top: 0;
  padding: 0;
  position: relative;
}

.pull-quote blockquote {
  padding-top: var(--vspace-2_5);
  border: none;
  max-width: 62rem;
  margin: 0 auto;
  position: relative;
}

.pull-quote blockquote p {
  color: var(--color-text-dark);
  font-weight: 400;
}

.pull-quote blockquote:before {
  content: "";
  height: var(--vspace-1);
  width: var(--vspace-1);
  top: var(--vspace-1);
  background: url("icon-quote.3f4f63e9.svg") center / contain;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.stats-tabs {
  margin: var(--vspace-1) 0;
  padding: 0;
}

.stats-tabs li {
  margin: 0 1.6rem var(--vspace-0_5) 0;
  border-right: 1px solid var(--color-border);
  padding: 0 1.5rem 0 0;
  display: inline-block;
}

.stats-tabs li:last-child {
  border: none;
  margin: 0;
  padding: 0;
}

.stats-tabs li a {
  font-size: var(--text-lg);
  font-family: var(--font-2);
  font-weight: 500;
  line-height: var(--vspace-1_5);
  color: var(--color-white);
  border: none;
  display: inline-block;
}

.stats-tabs li a:hover {
  color: var(--color-1-light);
}

.stats-tabs li a em {
  font-family: var(--font-1);
  font-size: var(--text-sm);
  line-height: var(--vspace-0_5);
  color: var(--color-text-light);
  margin: 0;
  font-style: normal;
  font-weight: normal;
  display: block;
}

.skill-bars {
  margin: var(--vspace-2) 0 var(--vspace-1);
  list-style: none;
}

.skill-bars li {
  background: var(--color-white);
  margin-bottom: calc(var(--vspace-2)  - .4rem);
  width: 100%;
  height: .4rem;
  padding: 0;
  position: relative;
}

.skill-bars li strong {
  left: 0;
  top: calc((var(--vspace-1) * 1.25) * -1);
  font-family: var(--font-2);
  color: var(--color-white);
  text-transform: uppercase;
  letter-spacing: .2em;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: var(--vspace-0_75);
  position: absolute;
}

.skill-bars li .progress {
  background: var(--color-1);
  height: 100%;
  position: relative;
}

.skill-bars li .progress span {
  right: 0;
  top: calc((var(--vspace-1)  + .8rem) * -1);
  font-family: var(--font-2);
  color: var(--color-white);
  background: var(--color-black);
  padding: calc(.25 * var(--space)) calc(.25 * var(--space));
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1;
  display: block;
  position: absolute;
}

.skill-bars li .progress span:after {
  border: 5px solid #0000;
  border-top-color: var(--color-black, var(--color-black));
  content: "";
  width: 0;
  height: 0;
  margin-left: -5px;
  position: absolute;
  bottom: -10px;
  left: 50%;
}

.skill-bars li .percent5 {
  width: 5%;
}

.skill-bars li .percent10 {
  width: 10%;
}

.skill-bars li .percent15 {
  width: 15%;
}

.skill-bars li .percent20 {
  width: 20%;
}

.skill-bars li .percent25 {
  width: 25%;
}

.skill-bars li .percent30 {
  width: 30%;
}

.skill-bars li .percent35 {
  width: 35%;
}

.skill-bars li .percent40 {
  width: 40%;
}

.skill-bars li .percent45 {
  width: 45%;
}

.skill-bars li .percent50 {
  width: 50%;
}

.skill-bars li .percent55 {
  width: 55%;
}

.skill-bars li .percent60 {
  width: 60%;
}

.skill-bars li .percent65 {
  width: 65%;
}

.skill-bars li .percent70 {
  width: 70%;
}

.skill-bars li .percent75 {
  width: 75%;
}

.skill-bars li .percent80 {
  width: 80%;
}

.skill-bars li .percent85 {
  width: 85%;
}

.skill-bars li .percent90 {
  width: 90%;
}

.skill-bars li .percent95 {
  width: 95%;
}

.skill-bars li .percent100 {
  width: 100%;
}

.alert-box {
  padding: var(--vspace-0_75) 4rem var(--vspace-0_75) 3.2rem;
  margin-bottom: var(--vspace-1);
  font-family: var(--font-1);
  font-weight: 500;
  font-size: var(--text-sm);
  line-height: var(--vspace-0_75);
  border-radius: 4px;
  position: relative;
}

.alert-box__close {
  cursor: pointer;
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
}

.alert-box__close:before, .alert-box__close:after {
  content: "";
  width: 2px;
  height: 12px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 5px;
}

.alert-box__close:before {
  transform: rotate(45deg);
}

.alert-box__close:after {
  transform: rotate(-45deg);
}

.alert-box--error {
  background-color: var(--color-error);
  color: var(--color-error-content);
}

.alert-box--error .alert-box__close:before, .alert-box--error .alert-box__close:after {
  background-color: var(--color-error-content);
}

.alert-box--success {
  background-color: var(--color-success);
  color: var(--color-success-content);
}

.alert-box--success .alert-box__close:before, .alert-box--success .alert-box__close:after {
  background-color: var(--color-success-content);
}

.alert-box--info {
  background-color: var(--color-info);
  color: var(--color-info-content);
}

.alert-box--info .alert-box__close:before, .alert-box--info .alert-box__close:after {
  background-color: var(--color-info-content);
}

.alert-box--notice {
  background-color: var(--color-notice);
  color: var(--color-notice-content);
}

.alert-box--notice .alert-box__close:before, .alert-box--notice .alert-box__close:after {
  background-color: var(--color-notice-content);
}

.wide {
  max-width: var(--width-wide);
}

.wider {
  max-width: var(--width-wider);
}

.narrow {
  max-width: var(--width-narrow);
}

.narrower {
  max-width: var(--width-narrower);
}

.section-title {
  margin-bottom: var(--vspace-2);
  display: block;
  position: relative;
}

.section-title:before {
  content: attr(data-num);
  font-family: var(--font-2);
  font-weight: 600;
  font-size: calc(var(--text-size) * 10);
  letter-spacing: -.02em;
  color: var(--color-text);
  opacity: .1;
  line-height: 1;
}

.section-title h3 {
  z-index: 2;
  color: var(--color-1);
  margin-top: 0;
  padding-bottom: 2em;
  font-weight: 500;
  position: absolute;
  bottom: 0;
  left: 1em;
}

.display-1 {
  font-size: var(--text-xxl);
  font-weight: 500;
  line-height: var(--vspace-1_75);
  margin-top: 0;
}

.item-title {
  font-family: var(--font-2);
  font-weight: 500;
  font-size: var(--text-lg);
  line-height: var(--vspace-1_25);
  margin-top: 0;
  margin-bottom: var(--vspace-0_5);
}

.item-list {
  margin-top: var(--vspace-2_5);
}

.item-list .item {
  margin-bottom: var(--vspace-0_5);
  padding-right: 8.6rem;
}

.slick-slider .slick-slide {
  outline: none;
}

.slick-slider .slick-dots {
  margin: var(--vspace-1) 0 0 0;
  text-align: center;
  width: 100%;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
}

.slick-slider .slick-dots li {
  cursor: pointer;
  width: 28px;
  height: 28px;
  margin: 0;
  padding: 10px;
  display: inline-block;
}

.slick-slider .slick-dots li button {
  cursor: pointer;
  line-height: 8px;
  font: 0 / 0 a;
  text-shadow: none;
  color: #0000;
  background: #fff;
  border: none;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0;
  padding: 0;
  display: block;
}

.slick-slider .slick-dots li button:hover, .slick-slider .slick-dots li button:focus {
  outline: none;
}

.slick-slider .slick-dots li.slick-active button, .slick-slider .slick-dots li:hover button {
  background: var(--color-1);
}

@media screen and (width <= 1100px) {
  .item-list .item {
    padding-right: 2rem;
  }
}

@media screen and (width <= 1000px) {
  .section-title:before {
    font-size: calc(var(--text-size) * 8);
  }
}

@media screen and (width <= 800px) {
  .section-title {
    margin-bottom: var(--vspace-1);
  }

  .item-list .item {
    padding-right: var(--gutter-md);
    margin-bottom: 0;
  }
}

@media screen and (width <= 600px) {
  .item-list .item {
    padding-right: var(--gutter-mob);
  }
}

@media screen and (width <= 400px) {
  .display-1 {
    font-size: var(--text-xl);
    line-height: var(--vspace-1_5);
  }

  .item-title {
    font-size: var(--text-md);
    line-height: var(--vspace-1);
  }

  .item-list .item {
    padding-right: 0;
  }
}

.s-header {
  z-index: 100;
  top: var(--vspace-0_75);
  width: 100%;
  height: 8.8rem;
  position: absolute;
  left: 0;
}

.s-header.offset {
  transition: all .5s;
  transform: translateY(-100%);
}

.s-header.scrolling {
  transform: translateY(0);
}

.s-header.sticky {
  background-color: var(--color-gray-19);
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
}

.s-header.sticky .s-header__content .btn {
  --btn-height: calc(var(--vspace-btn)  - 1.6rem) !important;
}

.s-header.sticky .header-nav a {
  color: #fff9;
}

.s-header.sticky.scrolling {
  opacity: 1;
  visibility: visible;
}

.s-header__content {
  height: 8.8rem;
  position: relative;
}

.s-header__logo {
  z-index: 101;
  margin: 0;
  padding: 0;
  transition: all .5s;
  display: inline-block;
  position: absolute;
  top: calc(50% - 2px);
  left: 8rem;
  transform: translateY(-50%);
}

.s-header__logo a {
  border: none;
  outline: 0;
  padding: 0;
  display: block;
}

.s-header__logo img {
  width: 90px;
  margin-bottom: 0;
}

.s-header__nav-wrap {
  padding-left: 21.6rem;
}

.s-header__nav {
  font-family: var(--font-2);
  text-transform: uppercase;
  letter-spacing: .4em;
  margin: 0;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 6rem;
  list-style: none;
}

.s-header__nav a {
  color: var(--color-gray-8);
}

.s-header__nav a:hover, .s-header__nav a:focus {
  color: #fff !important;
}

.s-header__nav li {
  margin: 0 1rem;
  padding-left: 0;
  display: inline-block;
}

.s-header__menu-toggle {
  z-index: 101;
  line-height: 4.2rem;
  font-family: var(--font-2);
  text-transform: uppercase;
  letter-spacing: .2rem;
  color: #fff;
  outline: none;
  width: 4.2rem;
  height: 4.2rem;
  font-size: 1.4rem;
  transition: all .5s;
  display: none;
  position: absolute;
  top: 1.6rem;
  right: 5.2rem;
}

.s-header__menu-toggle:hover, .s-header__menu-toggle:focus {
  color: #fff;
}

.s-header__menu-toggle span {
  font: 0 / 0 a;
  text-shadow: none;
  color: #0000;
  background-color: #fff;
  width: 2.4rem;
  height: 2px;
  margin-top: -1px;
  transition: all .5s;
  display: block;
  position: absolute;
  inset: 50% auto auto .9rem;
}

.s-header__menu-toggle span:before, .s-header__menu-toggle span:after {
  content: "";
  background-color: inherit;
  width: 100%;
  height: 100%;
  transition: all .5s;
  position: absolute;
  left: 0;
}

.s-header__menu-toggle span:before {
  top: -.9rem;
}

.s-header__menu-toggle span:after {
  bottom: -.9rem;
}

.s-header__menu-toggle.is-clicked span {
  background-color: #fff0;
  transition: all .1s;
}

.s-header__menu-toggle.is-clicked span:before, .s-header__menu-toggle.is-clicked span:after {
  background-color: #fff;
}

.s-header__menu-toggle.is-clicked span:before {
  top: 0;
  transform: rotate(135deg);
}

.s-header__menu-toggle.is-clicked span:after {
  bottom: 0;
  transform: rotate(225deg);
}

.s-header__content .btn {
  --btn-height: calc(var(--vspace-btn)  - 1.2rem);
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 2.8rem;
  font-size: 1rem;
  display: flex;
  position: absolute;
  top: 50%;
  right: 10rem;
  transform: translateY(-50%);
}

.s-header__content .btn svg {
  height: var(--vspace-0_5);
  width: var(--vspace-0_5);
  margin-right: 1.6rem;
  transform: translateY(-.1rem);
}

.s-header__content .btn svg path {
  fill: #fff;
}

.s-header__content .btn:hover, .s-header__content .btn:focus {
  color: #fff;
  background-color: var(--color-gray-19) !important;
  border-color: var(--color-gray-19) !important;
}

.sticky .s-header__content .btn:hover, .sticky .s-header__content .btn:focus {
  color: #000;
  background-color: #fff !important;
  border-color: #fff !important;
}

.sticky .s-header__content .btn:hover svg path, .sticky .s-header__content .btn:focus svg path {
  fill: #000;
}

@media screen and (width <= 1700px) {
  .s-header__logo {
    left: 6rem;
  }

  .s-header__nav-wrap {
    padding-left: 19.6rem;
  }

  .s-header__content .btn {
    right: 6rem;
  }
}

@media screen and (width <= 1000px) {
  .s-header__nav-wrap {
    padding-left: 17.6rem;
  }

  .s-header__content .btn {
    --btn-height: calc(var(--vspace-btn)  - 1.6rem) !important;
  }
}

@media screen and (width <= 900px) {
  .s-header {
    top: var(--vspace-0_5);
    height: 8rem;
  }

  .s-header.sticky .s-header__content .btn, .s-header .s-header__content .btn {
    --btn-height: calc(var(--vspace-btn)  - 1.2rem) !important;
  }

  .s-header.sticky .s-header__content .btn:hover, .s-header.sticky .s-header__content .btn:focus, .s-header .s-header__content .btn:hover, .s-header .s-header__content .btn:focus {
    color: #000;
    background-color: #fff !important;
    border-color: #fff !important;
  }

  .s-header.sticky .s-header__content .btn:hover svg path, .s-header.sticky .s-header__content .btn:focus svg path, .s-header .s-header__content .btn:hover svg path, .s-header .s-header__content .btn:focus svg path {
    fill: #000;
  }

  .s-header__content {
    background-color: var(--color-gray-19);
    padding: calc(5 * var(--space)) 6rem var(--vspace-2);
    top: calc(var(--vspace-0_5) * -1);
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    left: 0;
    transform: translateY(-100%);
  }

  .s-header__content .btn {
    margin-top: var(--vspace-1_5);
    text-align: center;
    opacity: 0;
    visibility: hidden;
    position: static;
    transform: translateY(-2rem);
  }

  .s-header__nav-wrap {
    opacity: 0;
    visibility: hidden;
    padding: 0;
    transform: translateY(-2rem);
  }

  .s-header__nav {
    width: 100%;
    padding: 0;
    display: block;
  }

  .s-header__nav a {
    display: block;
  }

  .s-header__nav li {
    border-top: 1px solid #ffffff08;
    margin: 0;
    display: block;
  }

  .s-header__nav li:last-child {
    border-bottom: 1px solid #ffffff08;
  }

  .s-header__menu-toggle {
    display: block;
  }

  .menu-is-open .s-header__content {
    transition: transform .5s cubic-bezier(.215, .61, .355, 1), -webkit-transform .5s cubic-bezier(.215, .61, .355, 1);
    transform: translateY(0);
  }

  .menu-is-open .s-header__content .btn {
    opacity: 1;
    visibility: visible;
    transition: transform .5s cubic-bezier(.215, .61, .355, 1) .7s, opacity .5s cubic-bezier(.215, .61, .355, 1) .7s, -webkit-transform .5s cubic-bezier(.215, .61, .355, 1) .7s;
    transform: translateY(0);
  }

  .menu-is-open .s-header__nav-wrap {
    opacity: 1;
    visibility: visible;
    transition: all .5s cubic-bezier(.215, .61, .355, 1) .6s;
    transform: translateY(0);
  }
}

@media screen and (width <= 600px) {
  .s-header__logo {
    left: 4rem;
  }

  .s-header__menu-toggle {
    right: 4rem;
  }

  .s-header__content {
    padding: calc(5 * var(--space)) 4rem var(--vspace-2);
  }

  .s-header__content .btn {
    display: block;
  }

  .s-header__content .btn svg {
    transform: translateY(.3rem);
  }
}

.s-hero {
  min-height: calc(25.5 * var(--space));
  background-color: #121417;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.s-hero__bg {
  opacity: .1;
  background-image: url("background-logo-dashed.fca239f3.svg");
  background-position: 90%;
  background-repeat: no-repeat;
  background-size: auto 50%;
  position: absolute;
  inset: 0 0 0 36.5vw;
}

.s-hero__bg:after {
  content: "";
  background: var(--color-black);
  opacity: 0;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.s-hero__bg .gradient-overlay {
  opacity: .35;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.s-hero__content {
  align-items: flex-end;
  height: 100%;
  padding-top: 20vh;
  padding-bottom: 7vh;
  position: relative;
}

.s-hero__content h1 {
  letter-spacing: -.04em;
  color: var(--color-1);
  margin-bottom: 0;
  font-size: 22rem;
  font-weight: 600;
  line-height: 1;
  position: relative;
}

.s-hero__content h1:before {
  content: "";
  opacity: .25;
  background-image: url("dots.d8e452c2.svg");
  width: 20rem;
  height: 10rem;
  display: block;
  position: absolute;
  bottom: .14em;
  right: 100%;
  transform: translateX(-4rem);
}

.s-hero__content img {
  margin-bottom: 0;
}

.s-hero__content-about {
  font-family: var(--font-2);
  color: var(--color-white);
  padding-left: 3.6em;
  font-size: 3.6rem;
  font-weight: 400;
  line-height: 1.444;
  position: relative;
}

.s-hero__content-about:before {
  content: "";
  background-color: var(--color-border);
  width: 2.4em;
  height: 1px;
  display: block;
  position: absolute;
  top: .8em;
  left: .5em;
}

.s-hero__content-about p {
  margin-bottom: var(--vspace-2);
}

.s-hero__content-social {
  font-size: 1.8rem;
  line-height: var(--vspace-1);
  display: inline-block;
}

.s-hero__content-social a {
  color: #fff;
  margin-right: 1.2rem;
}

.s-hero__content-social a:hover, .s-hero__content-social a:focus {
  color: var(--color-1-light);
}

.s-hero__content-social a:last-child {
  margin-right: 0;
}

.s-hero__video {
  position: absolute;
  bottom: 20vh;
  right: 10.4rem;
}

.s-hero__video svg {
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.s-hero__video svg path {
  fill: #fff;
}

.s-hero__video-link {
  background-color: var(--color-1);
  color: #fff;
  border-radius: 100%;
  width: 9.6rem;
  height: 9.6rem;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
  display: block;
  position: relative;
}

.s-hero__video-link:hover, .s-hero__video-link:focus {
  background-color: var(--color-gray-19);
  outline: 0;
}

.s-hero__video-text {
  display: none;
}

.s-hero__scroll {
  transform-origin: 0 100%;
  position: absolute;
  bottom: 0;
  left: 6vw;
  transform: rotate(90deg)translateX(-200px);
}

.s-hero__scroll-link {
  font-family: var(--font-2);
  text-transform: uppercase;
  letter-spacing: .4em;
  text-align: left;
  vertical-align: middle;
  color: var(--color-text);
  height: 3.2rem;
  font-size: 9px;
  font-weight: 300;
  line-height: 3.2rem;
  display: inline-block;
  position: relative;
}

.s-hero__scroll-link:hover, .s-hero__scroll-link:focus {
  color: #fff;
}

html.ss-preload .s-hero__content {
  opacity: 0;
}

html.ss-loaded .s-hero__content {
  animation-name: fadeIn;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(.23, 1, .32, 1);
  animation-fill-mode: both;
}

html.no-csstransitions .s-hero__content {
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

@media screen and (width <= 1600px) {
  .s-hero__content {
    max-width: 1100px;
  }
}

@media screen and (width <= 1400px) {
  .s-hero__content {
    max-width: 1000px;
  }

  .s-hero__content h1 {
    font-size: 20rem;
  }

  .s-hero__content-about {
    font-size: 3.3rem;
  }

  .s-hero__video-link {
    width: 8.8rem;
    height: 8.8rem;
  }
}

@media screen and (width <= 1200px) {
  .s-hero__content {
    max-width: 900px;
  }
}

@media screen and (width <= 1100px) {
  .s-hero__content {
    max-width: 800px;
  }

  .s-hero__content h1 {
    font-size: 14rem;
  }
}

@media screen and (width <= 1000px) {
  .s-hero__content {
    max-width: 700px;
  }

  .s-hero__content-about {
    padding-left: .6em;
  }

  .s-hero__content-about:before {
    display: none;
  }

  .s-hero__video {
    bottom: 7vh;
    right: calc(100% - 48vw);
  }

  .s-hero__video svg {
    margin-right: .4rem;
    display: inline-block;
    position: static;
    transform: translate3d(0, 0, 0);
  }

  .s-hero__video-link {
    border-radius: 0;
    width: auto;
    height: auto;
    transition: background-color ease-in-out, color .3s ease-in-out;
    display: inline-block;
    background-color: #0000 !important;
  }

  .s-hero__video-text {
    display: inline-block;
  }

  .s-hero__scroll {
    transform-origin: 100% 100%;
    left: auto;
    right: 4.8rem;
    transform: rotate(90deg)translateX(-100px)translateY(3.2rem);
  }

  .s-hero__scroll-link {
    color: var(--color-white);
  }
}

@media screen and (width <= 900px) {
  .s-hero__bg {
    width: 50vw;
    height: 50vh;
    display: block;
    inset: 12rem 0 auto auto;
  }

  .s-hero__content {
    max-width: 600px;
  }

  .s-hero__video {
    right: calc(100% - 54vw);
  }

  .s-hero__scroll-link {
    color: var(--color-text);
  }
}

@media screen and (width <= 800px) {
  .s-hero__content {
    padding-bottom: 8vh;
  }

  .s-hero__content h1:before {
    bottom: .8em;
    left: -1.2rem;
    transform: translateX(0);
  }

  .s-hero__video {
    bottom: 8vh;
  }
}

@media screen and (width <= 700px) {
  .s-hero__content h1 {
    font-size: 16rem;
  }

  .s-hero__content-about {
    font-size: 2.8rem;
  }

  .s-hero__content-social, .s-hero__video {
    font-size: 1.6rem;
  }
}

@media screen and (width <= 600px) {
  .s-hero__content h1 {
    font-size: 19vw;
  }

  .s-hero__content-about {
    padding-right: 15vw;
  }

  .s-hero__content-about br {
    display: none;
  }

  .s-hero__scroll {
    right: 2.8rem;
  }

  .s-hero__video {
    right: calc(100% - 30rem);
  }
}

@media screen and (width <= 500px) {
  .s-hero__content h1:before {
    bottom: .6em;
    left: -6rem;
  }

  .s-hero__content-about {
    padding-left: .5em;
    padding-right: 4rem;
    font-size: 2.6rem;
  }
}

@media screen and (width <= 400px) {
  .s-header__logo {
    left: 3.2rem;
  }

  .s-header__menu-toggle {
    right: 3.2rem;
  }

  .s-hero__content h1:before {
    left: -8rem;
  }

  .s-hero__content {
    padding-bottom: 14vh;
  }

  .s-hero__content-about {
    padding-right: 6rem;
    font-size: 2.2rem;
  }

  .s-hero__video {
    bottom: 8.2vh;
    left: 9vw;
    right: auto;
  }
}

@media screen and (width <= 360px) {
  .s-hero__scroll {
    padding-right: 0;
    right: 2rem;
    transform: rotate(90deg)translateX(-4rem)translateY(3.2rem);
  }
}

.s-about {
  background-color: var(--color-bg);
  padding-top: calc(7 * var(--space));
  padding-bottom: 0;
  position: relative;
}

.s-about:before {
  content: "";
  opacity: .25;
  background-image: url("dots.d8e452c2.svg");
  width: 28rem;
  height: 17.6rem;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.s-about .horizontal-line {
  width: 32px;
  height: 20rem;
  position: absolute;
  top: -7rem;
  left: calc(6vw + 1px);
}

.s-about .horizontal-line:before {
  content: "";
  background-color: var(--color-1);
  width: 1px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: calc(50% + 1px);
}

.s-about__photo-block {
  position: relative;
}

.s-about__photo {
  top: var(--vspace-0_5);
  right: var(--gutter-lg);
  left: var(--gutter-lg);
  bottom: var(--vspace-1);
  background-image: url("croatia.c7f14b53.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}

.s-about__process {
  counter-reset: ctr;
}

.s-about__process h2 {
  padding-left: 2.5em;
  position: relative;
}

.s-about__process h2:before {
  content: counter(ctr);
  counter-increment: ctr;
  font-family: var(--font-2);
  font-size: var(--text-size);
  text-align: center;
  color: #fff;
  background-color: var(--color-1);
  height: var(--vspace-1_5);
  width: var(--vspace-1_5);
  font-weight: 600;
  line-height: var(--vspace-1_5);
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -5px;
  left: 0;
}

.s-about__process .item-process {
  position: relative;
}

@media screen and (width <= 1400px) {
  .s-about:before {
    left: auto;
    right: 15vw;
  }
}

@media screen and (width <= 1000px) {
  .s-about {
    padding-top: calc(5 * var(--space));
  }

  .s-about .horizontal-line {
    left: auto;
    right: 4.6rem;
  }
}

@media screen and (width <= 900px) {
  .s-about__photo-block {
    display: none;
  }
}

@media screen and (width <= 800px) {
  .s-about:before {
    display: none;
  }
}

@media screen and (width <= 600px) {
  .s-about .horizontal-line {
    right: 2.6rem;
  }
}

@media screen and (width <= 400px) {
  .s-about {
    padding-top: var(--vspace-4);
  }

  .s-about__process h3 {
    margin-bottom: var(--vspace-0_75);
    padding-left: 3em;
  }
}

@media screen and (width <= 360px) {
  .s-about .horizontal-line {
    height: 16rem;
    top: -2rem;
    right: 1.8rem;
  }
}

.s-services {
  background-color: var(--color-bg);
  padding-top: calc(6 * var(--space));
  padding-bottom: var(--vspace-2);
}

.s-services .lead {
  margin-top: var(--vspace-0_25);
}

.s-services .item-service {
  margin-bottom: 0;
}

.service-icon {
  width: calc(2.25 * var(--space));
  height: calc(2.25 * var(--space));
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}

.service-icon--product-design {
  background-image: url("icon-product-design.f795e0ad.svg");
}

.service-icon--branding {
  background-image: url("icon-branding.a94df629.svg");
}

.service-icon--frontend {
  background-image: url("icon-frontend.af9870be.svg");
}

.service-icon--research {
  background-image: url("icon-research.bc85b41b.svg");
}

.service-icon--illustration {
  background-image: url("icon-illustration.939f9298.svg");
}

.service-icon--ecommerce {
  background-image: url("icon-ecommerce.fb2b80a8.svg");
}

.service-icon--integration {
  background-image: url("integration.94ea8407.svg");
}

.service-icon--application {
  background-image: url("application.1b508980.svg");
}

.service-icon--backend {
  background-image: url("repair.4f7e417f.svg");
}

.service-icon--website {
  background-image: url("website.94fe0da4.svg");
}

.service-icon--ecommerce2 {
  background-image: url("ecommerce.dabf137d.svg");
}

.service-icon--hosting {
  background-image: url("hosting.f0b52dc3.svg");
}

@media screen and (width <= 1200px) {
  .s-services {
    padding-top: calc(5 * var(--space));
  }
}

@media screen and (width <= 400px) {
  .s-services {
    padding-top: var(--vspace-4);
  }
}

.s-clients {
  background-color: var(--color-bg);
  padding-top: calc(6 * var(--space));
  padding-bottom: var(--vspace-4);
  position: relative;
}

.clients-outer {
  margin-top: var(--vspace-2);
  margin-bottom: var(--vspace-2);
  text-align: center;
  max-width: 1080px;
  position: relative;
}

.clients-outer .column {
  min-width: 0;
  min-height: 0;
}

.clients__slide {
  opacity: .15;
  text-align: center;
  padding: 0;
  transition: opacity .5s;
  display: block;
}

.clients__slide img {
  padding: 0 2rem;
}

.clients__slide:hover, .clients__slide:focus {
  opacity: 1;
}

.testimonials {
  background-color: var(--color-bg);
  padding-top: var(--vspace-4);
  padding-bottom: var(--vspace-1_5);
  text-align: center;
  max-width: 800px;
}

.testimonials .slick-arrow {
  z-index: 2;
  top: calc(2.5 * var(--space));
  width: var(--vspace-1);
  height: var(--vspace-1);
  cursor: pointer;
  font: 0 / 0 a;
  text-shadow: none;
  color: #0000;
  opacity: .15;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  margin: 0;
  padding: 0;
  transition: all .3s;
  position: absolute;
}

.testimonials .slick-arrow:hover, .testimonials .slick-arrow:focus {
  opacity: 1;
}

.testimonials .slick-prev {
  background-image: url("icon-arrow-left.e283b522.svg");
  left: -100px;
}

.testimonials .slick-next {
  background-image: url("icon-arrow-right.291d0fd9.svg");
  right: -100px;
}

.testimonial-slider {
  position: relative;
}

.testimonial-slider p {
  font-size: var(--text-lg);
  color: #fff;
  line-height: 1.852;
}

.testimonial-slider__slide {
  padding: 0 var(--gutter-lg);
  position: relative;
}

.testimonial-slider__author {
  margin-top: var(--vspace-1);
  text-align: left;
  min-height: 7.2rem;
  padding-left: 8.4rem;
  display: inline-block;
  position: relative;
}

.testimonial-slider__avatar {
  width: var(--vspace-2);
  height: var(--vspace-2);
  border-radius: 100%;
  outline: none;
  margin-bottom: 0;
  margin-right: 1.6rem;
  position: absolute;
  top: 0;
  left: 0;
}

.testimonial-slider__cite {
  padding-top: calc(.125 * var(--space));
  font-style: normal;
  display: block;
}

.testimonial-slider__cite strong {
  font-size: var(--text-size);
  color: var(--color-1);
}

.testimonial-slider__cite span {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: var(--vspace-0_5);
  color: var(--color-text-light);
  display: block;
}

@media screen and (width <= 1400px) {
  .clients-outer {
    max-width: 1000px;
  }

  .testimonials {
    max-width: 700px;
  }

  .testimonials .slick-prev {
    left: -60px;
  }

  .testimonials .slick-next {
    right: -60px;
  }
}

@media screen and (width <= 900px) {
  .testimonials {
    max-width: 600px;
  }

  .testimonials .slick-arrow {
    top: auto;
    bottom: var(--vspace-1);
  }

  .testimonials .slick-prev {
    left: 1.2rem;
  }

  .testimonials .slick-next {
    right: 1.2rem;
  }
}

@media screen and (width <= 800px) {
  .clients-outer {
    max-width: 640px;
  }

  .testimonial-slider p {
    font-size: var(--text-md);
  }

  .testimonial-slider__author {
    margin-top: var(--vspace-0_5);
    text-align: center;
    min-height: 0;
    padding-left: 0;
  }

  .testimonial-slider__avatar {
    margin: 0 auto;
    position: static;
  }
}

@media screen and (width <= 600px) {
  .testimonial-slider__slide {
    padding: 0;
  }

  .testimonial-slider__avatar {
    width: var(--vspace-1_75);
    height: var(--vspace-1_75);
  }
}

@media screen and (width <= 400px) {
  .testimonials {
    padding-top: var(--vspace-2);
  }

  .testimonial-slider p {
    font-size: var(--text-size);
  }
}

.s-contact {
  background-color: var(--color-1);
  padding-top: calc(6 * var(--space));
  padding-bottom: var(--vspace-2);
}

.s-contact__top .h6 {
  color: #000;
  margin-top: 0;
  margin-bottom: var(--vspace-1);
}

.s-contact__email {
  margin-top: var(--vspace-1_5);
  font-family: var(--font-2);
  font-size: calc(var(--text-size) * 6.6);
  letter-spacing: -.01em;
  font-weight: 500;
  line-height: 1;
}

.s-contact__email a {
  color: var(--color-1-darker-4);
}

.s-contact__email a:hover, .s-contact__email a:focus {
  color: var(--color-white);
}

@media screen and (width <= 1600px) {
  .s-contact__email {
    font-size: calc(var(--text-size) * 6);
  }
}

@media screen and (width <= 1200px) {
  .s-contact__email {
    font-size: 10vw;
  }
}

@media screen and (width <= 400px) {
  .s-contact {
    padding-top: var(--vspace-4);
  }
}

.s-footer {
  padding-top: var(--vspace-2);
  padding-bottom: var(--vspace-4);
  color: var(--color-1-darker-4);
}

.s-footer a {
  color: #fff;
}

.s-footer .h6 {
  color: #000;
  margin-top: 0;
}

.s-footer__block {
  font-size: var(--text-lg);
  line-height: calc(1.25 * var(--space));
}

.s-footer__block .h6 {
  margin-bottom: var(--vspace-0_75);
}

.s-footer__block--end {
  font-size: var(--text-sm);
  line-height: var(--vspace-1);
  padding-top: var(--vspace-1_75);
  position: relative;
}

.s-footer__block--end:before {
  content: "";
  background-color: var(--color-1-darker-1);
  top: var(--vspace-0_25);
  width: 70%;
  height: 1px;
  display: block;
  position: absolute;
  left: 15%;
}

.s-footer__block--end .btn {
  color: #000;
  margin-right: 0;
  margin-bottom: var(--vspace-1);
  background-color: #fff;
  border: .2rem solid #fff;
}

.s-footer__block--end .btn:hover, .s-footer__block--end .btn:focus {
  background-color: var(--color-gray-19);
  border: .2rem solid var(--color-gray-19);
  color: #fff;
}

.s-footer__list {
  margin-left: 0;
  list-style: none;
}

.s-footer__list li {
  padding-left: 0;
}

.ss-copyright {
  z-index: 2;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 300;
  position: relative;
}

.ss-copyright a {
  color: #000;
  border-bottom: 1px dotted #fff3;
  display: inline-block;
}

.ss-copyright a:hover, .ss-copyright a:focus {
  border-bottom: 1px solid #fff;
}

.ss-copyright span {
  display: inline-block;
}

.ss-copyright span:after {
  content: "|";
  color: #ffffff40;
  padding: 0 .8rem 0 1rem;
  display: inline-block;
}

.ss-copyright span:last-child:after {
  display: none;
}

.ss-go-top {
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  bottom: var(--vspace-1);
  transition: all .5s cubic-bezier(.215, .61, .355, 1);
  position: fixed;
  right: 4rem;
  transform: scale(0);
}

.ss-go-top a {
  text-align: center;
  border: 0;
  border-radius: 50%;
  width: 6.4rem;
  height: 6.4rem;
  line-height: 6.4rem;
  text-decoration: none;
  transition: all .3s;
  display: block;
  position: relative;
  background-color: var(--color-gray-19) !important;
}

.ss-go-top.link-is-visible {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

@media screen and (width <= 1400px) {
  .s-footer > .row {
    max-width: 1100px;
  }
}

@media screen and (width <= 1200px) {
  .s-footer > .row {
    max-width: 800px;
  }

  .s-footer__block--end {
    text-align: center;
  }

  .s-footer__block--end:before {
    display: none;
  }
}

@media screen and (width <= 800px) {
  .s-footer {
    padding-top: var(--vspace-1);
  }

  .ss-go-top a {
    width: 6rem;
    height: 6rem;
    font-size: 1.6rem;
    line-height: 6rem;
  }
}

@media screen and (width <= 600px) {
  .ss-go-top {
    bottom: 2.4rem;
    right: 2.4rem;
  }

  .ss-copyright span {
    display: block;
  }

  .ss-copyright span:after {
    display: none;
  }
}

@media screen and (width <= 400px) {
  .s-footer__block {
    font-size: var(--text-md);
    line-height: calc(1.125 * var(--space));
  }

  .ss-go-top a {
    width: 4.8rem;
    height: 4.8rem;
    font-size: 1.4rem;
    line-height: 4.8rem;
  }

  .ss-copyright {
    font-size: 1.4rem;
  }
}

.skill-list .column {
  padding: var(--gutter-mob);
}

.skill-container {
  color: var(--color-text);
  letter-spacing: .2rem;
  padding: calc(var(--gutter-md));
  text-transform: uppercase;
  background: #64646408;
  border-radius: .25rem;
  align-items: center;
  font-size: 1.5rem;
  transition: all .25s;
  display: flex;
  position: relative;
}

.skill-container > * {
  pointer-events: none;
}

.skill-container:hover, .skill-container:focus {
  color: #222;
  box-shadow: inset 25vw 0 #cdab89;
}

.skill-container i {
  margin-right: 1rem;
  font-size: 3rem;
}
/*# sourceMappingURL=index.0ec8557f.css.map */
